export function useSuccessToast(title: string, description: string) {
  if (import.meta.server) {
    return;
  }

  const toast = useToast();
  toast.add({ icon: "i-ic-baseline-check", title: title, description: description, color: "green" });
}

export function useFailToast(title: string, description: string) {
  if (import.meta.server) {
    return;
  }

  const toast = useToast();
  toast.add({
    icon: "i-ic-baseline-warning-amber",
    title: title,
    description: description,
    color: "red",
    timeout: 30 * 1000,
  });
}
